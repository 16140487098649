
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticProps } from 'next';
import { SupportedLocale } from 'i18n';
import { HomePage } from 'modules/company/pages/HomePage';
import { MarketplaceAPI } from 'revibe-api';
export default HomePage;
const getStaticProps: GetStaticProps = async ({ locale }) => {
    const { data: articles } = await MarketplaceAPI.getBlogArticles(locale as SupportedLocale, 5);
    let igPosts: {
        media_url: string;
        permalink: string;
        id: string;
    }[] = [];
    try {
        const res = await fetch(`https://graph.instagram.com/${process.env.IG_USER_ID as string}/media?access_token=${process.env.IG_ACCESS_TOKEN as string}&fields=media_url,permalink,caption,media_type`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            credentials: 'include',
        });
        if (res.ok) {
            const data = await res.json();
            igPosts = data.data.slice(0, 6);
        }
    }
    catch (err: any) {
        console.error(err);
    }
    return {
        props: {
            articles,
            igPosts,
        },
    };
};

    async function __Next_Translate__getStaticProps__18a128d3309__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18a128d3309__ as getStaticProps }
  