import React from 'react';

import { AspectRatio, cn } from 'revibe-ui';
import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';
import { SpacedContainer } from 'revibe-ui';

import { ClampText } from 'ui/components/ClampText';
import { Link } from 'ui/components/Link';
import Image from 'next/image';

type Props = {
  preHeading?: string;
  heading: string;
  description: string;
  imageSrc: string;
  to: string;
  imageFirst?: boolean;
  ctaText?: string;
  content?: React.ReactNode;
};

export const BoxContentImage = ({
  preHeading,
  heading,
  description,
  imageSrc,
  to,
  imageFirst = false,
  ctaText,
  content,
}: Props) => {
  const { tu } = useTranslation(I18N_NAMESPACES.COMMON);

  return (
    <div>
      <div className="overflow-hidden p-4 md:hidden">
        <h3 className="font-formula text-center text-4xl text-black">
          {heading}
        </h3>
        {preHeading && (
          <p className="break-words text-center text-black">{preHeading}</p>
        )}
      </div>
      <div
        className={cn(
          'flex border border-black md:flex-row',
          imageFirst ? 'flex-col' : 'flex-col-reverse'
        )}
      >
        {imageFirst && (
          <div className="md:w-[35%]">
            <AspectRatio ratio={3 / 4} className="border-[16px] border-black">
              <Image
                src={imageSrc}
                alt={heading}
                fill
                className="object-cover"
              />
            </AspectRatio>
          </div>
        )}
        <div className="flex-1">
          <SpacedContainer className="p-4 text-black">
            <div className="hidden md:block">
              {preHeading && <p>{preHeading}</p>}
              <h3 className="font-formula text-6xl">{heading}</h3>
            </div>
            {content ? (
              content
            ) : (
              <>
                <ClampText showReadMore={false} lines={10}>
                  <p className="max-w-3xl whitespace-pre-wrap">{description}</p>
                </ClampText>
                <Link to={to}>
                  <Button variant="outline">
                    {ctaText || tu('discover-more')}
                  </Button>
                </Link>
              </>
            )}
          </SpacedContainer>
        </div>
        {!imageFirst && (
          <div className="md:w-[35%]">
            <AspectRatio ratio={3 / 4} className="border-[16px] border-black">
              <Image
                src={imageSrc}
                alt={heading}
                fill
                className="object-cover"
              />
            </AspectRatio>
          </div>
        )}
      </div>
    </div>
  );
};
