export const NEWS = [
  {
    slug: 'von-dutch-parisfw-23',
    tags: ['partnership', 'capsule-collection'],
    image:
      'https://res.cloudinary.com/revibe/image/upload/f_auto/q_auto/v1689169542/News/Von%20Dutch%20FW%2023/image-preview-news-vondutch_1_japviq.jpg',
  },
  {
    slug: 'hm-beyond',
    tags: ['partnership', 'capsule-collection'],
    image:
      'https://res.cloudinary.com/revibe/image/upload/f_auto/q_auto/v1680275026/News/H-m/image-preview-news-page-1_v0bkc0.webp',
  },
  {
    slug: 'lynkco-upcycling-collection',
    tags: ['partnership', 'capsule-collection'],
    image:
      'https://res.cloudinary.com/revibe/image/upload/f_auto/q_auto/v1680184922/News/Lynk%20Co/8eZ7pQ1qDXDLO9uGOf98q40iCA_urm0b1.webp',
  },
  {
    slug: 'eastpak-plug-mi-22',
    tags: ['partnership', 'capsule-collection'],
    image:
      'https://res.cloudinary.com/revibe/image/upload/f_auto/q_auto/v1680185407/News/Eastpak/pIM6iM1WGQOzVWUOsFfVyJkfE_e0digc.webp',
  },
  {
    slug: 'upcycling-festival-22',
    tags: ['event', 'paris'],
    image:
      'https://res.cloudinary.com/revibe/image/upload/f_auto/q_auto/v1680185432/News/Upcycling%20festival/sV63hioRXmnzebFzxgaCXM2yg_jnrrja.webp',
  },
];
