import React from 'react';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { SHOP_ROUTES } from 'routes';

import Image from 'next/image';
import { useItemCategoriesByGroup } from 'modules/item/hooks';
import { ITEM_FILTERS, ITEM_ORDER } from 'modules/item/utils/itemFilter';
import { buildItemsListingRoute } from 'modules/catalog/utils';

import { Link } from 'ui/components/Link';

export const CategoriesNavBar = () => {
  const { tu } = useTranslation('shop.categories');
  const maleCategories = useItemCategoriesByGroup('male', [28, 27]);
  const femaleCategories = useItemCategoriesByGroup('female');

  const categoriesNavbarEntries = [
    {
      id: 'all-sellers',
      label: tu('all-sellers'),
      to: SHOP_ROUTES.SELLERS,
    },
    {
      id: 'female',
      label: tu('female', 'common'),
      subEntries: femaleCategories,
      photo:
        'https://res.cloudinary.com/revibe/image/upload/c_scale,w_621,f_auto/v1644933577/categories/181849371_785967509016078_7445328207881050732_n_wuvuwc.jpg',
    },
    {
      id: 'male',
      label: tu('male', 'common'),
      subEntries: maleCategories,
      photo:
        'https://res.cloudinary.com/revibe/image/upload/c_scale,w_621,f_auto/v1644934553/categories/i9iyewv5irb8fymfkydw_1_doxakn.jpg',
    },
    {
      id: 'promotions',
      label: tu('sales', 'shop'),
      to: buildItemsListingRoute('all', null, {
        [ITEM_FILTERS.ONLY_SALES]: true,
      }),
    },
    {
      id: 'new-in',
      label: tu('new', 'shop'),
      to: buildItemsListingRoute('all', null, {
        [ITEM_FILTERS.ORDER]: ITEM_ORDER.NEW,
      }),
    },
  ];

  return (
    <NavigationMenu className="child:w-full w-full">
      <NavigationMenuList className="w-full">
        {categoriesNavbarEntries.map((entry) => (
          <NavigationMenuItem key={entry.id} className="-mb-1.5">
            {entry.subEntries && entry.subEntries.length > 0 ? (
              <NavigationMenuTrigger className="font-formula text-2xl uppercase">
                {entry.label}
              </NavigationMenuTrigger>
            ) : (
              <NavigationMenuLink
                href={entry.to}
                className="font-formula text-2xl uppercase"
              >
                {entry.label}
              </NavigationMenuLink>
            )}
            {entry.subEntries && entry.subEntries.length > 0 && (
              <NavigationMenuContent>
                <div className="grid w-fit grid-cols-3">
                  {entry.subEntries.map((subEntry) => (
                    <ul
                      className="list-none bg-white"
                      key={entry.id + subEntry.id}
                    >
                      {subEntry.to && (
                        <Link
                          hasDecoration={false}
                          className="font-formula text-2xl uppercase text-gray-800 hover:text-gray-600"
                          to={subEntry.to}
                        >
                          <li className="px-6 py-3 first:pt-3">
                            {subEntry.label}
                          </li>
                        </Link>
                      )}
                      {subEntry.sections.map((section) => (
                        <li
                          key={entry.id + section.id}
                          className="px-6 py-3 last:border-b-0"
                        >
                          <Link
                            hasDecoration={false}
                            className="text-gray-800 hover:text-gray-600"
                            to={section.to}
                          >
                            {section.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ))}
                  {entry.photo && (
                    <div className="relative max-h-[360px] min-h-full w-[296px] border-l border-black">
                      <Image
                        src={entry.photo}
                        alt={entry.label}
                        fill
                        className="object-cover"
                      />
                    </div>
                  )}
                </div>
              </NavigationMenuContent>
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
};
