import { getDynamicRouteUrl } from 'revibe-api/src/utils/routes';
import { AspectRatio, SpacedContainer } from 'revibe-ui';
import { NEWS_ROUTES } from 'routes';
import Image from 'next/image';
import Link from 'next/link';
import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { ClampText } from 'ui/components/ClampText';
import { Tag } from 'ui/components/Tag';

interface Props {
  news: {
    slug: string;
    tags: string[];
    image: string;
  };
}

export const NewsPreview = ({ news }: Props) => {
  const { tu } = useTranslation(`${I18N_NAMESPACES.NEWS}.home`);
  return (
    <Link
      href={getDynamicRouteUrl(NEWS_ROUTES.ARTICLE, {
        slug: news.slug,
      })}
    >
      <div className="group cursor-pointer overflow-hidden bg-black">
        <AspectRatio
          ratio={1 / 1}
          className="w-full overflow-hidden  transition-opacity hover:opacity-70"
        >
          <Image
            src={news.image}
            alt={tu(`previews.${news.slug}`)}
            fill
            className="object-cover"
          />
        </AspectRatio>

        <SpacedContainer className="bg-black p-2">
          <div className="text-gray-50">
            <ClampText showReadMore={false} lines={2}>
              <h2 className="font-syne text-xl uppercase text-gray-50">
                {tu(`previews.${news.slug}`)}
              </h2>
            </ClampText>
            <SpacedContainer type="horizontal" className="mt-2">
              {news.tags.map((tag) => (
                <Tag type="dark" key={tag}>
                  {tu(`tags.${tag}`)}
                </Tag>
              ))}
            </SpacedContainer>
          </div>
        </SpacedContainer>
      </div>
    </Link>
  );
};
