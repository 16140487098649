import { MarketplaceAPI } from 'revibe-api';
import { useTranslation } from 'i18n/hooks';
import { AspectRatio, SpacedContainer } from 'revibe-ui';
import { BLOG_ROUTES } from 'routes';

import { getDynamicRouteUrl } from 'modules/common/utils';
import Image from 'next/image';
import { ClampText } from 'ui/components/ClampText';
import { Link } from 'ui/components/Link';
import { Tag } from 'ui/components/Tag';

type Props = {
  article: MarketplaceAPI.BlogArticle;
};

export const BlogArticlePreview = ({ article }: Props) => {
  const { language } = useTranslation();
  return (
    <Link
      hasDecoration={false}
      to={getDynamicRouteUrl(BLOG_ROUTES.ARTICLE, {
        category: article.category.data.attributes.slug,
        slug: article.slug,
      })}
    >
      <div className="bg-green-fluo group cursor-pointer overflow-hidden">
        {article.image?.data !== undefined && (
          <AspectRatio
            ratio={3 / 4}
            className="border-green-fluo w-full overflow-hidden border-8 transition-opacity hover:opacity-70"
          >
            <Image
              src={article.image?.data?.attributes.formats.medium.url}
              alt={article.image?.data?.attributes.caption}
              fill
              className="object-cover"
            />
          </AspectRatio>
        )}
        <SpacedContainer className="bg-white p-2">
          <div className="">
            <ClampText showReadMore={false} lines={2}>
              <h2 className="font-formula text-2xl uppercase tracking-wide">
                {article.title}
              </h2>
            </ClampText>
            <SpacedContainer type="horizontal" className="mt-2">
              <Tag>{article.category.data.attributes.name}</Tag>
              <p className="text-xs text-gray-500">
                {new Date(article.createdAt).toLocaleDateString(language, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            </SpacedContainer>
          </div>
          <div className="child:text-gray-500 child:text-sm">
            <ClampText showReadMore={false} lines={3}>
              {article.description}
            </ClampText>
          </div>
        </SpacedContainer>
      </div>
    </Link>
  );
};
