import React from 'react';

import { SUPPORTED_LOCALES } from 'i18n';
import { useTranslation } from 'i18n/hooks';

import { useCategories } from 'modules/item/hooks/useCategories';
import { CategoryBox } from 'modules/catalog/components/CategoryBox';
import { buildItemsListingRoute } from 'modules/catalog/utils';

import { Link } from 'ui/components/Link';

type CategoryCarouselItemProps = {
  categoryID: string;
  type: 'unisex' | 'male' | 'female';
};

type Props = {
  categoriesToInclude: {
    id: string;
    type: 'unisex' | 'male' | 'female';
  }[];
};

const CategoryCarouselItem = ({
  categoryID,
  type,
}: CategoryCarouselItemProps) => {
  const { tu, language } = useTranslation();
  const { categories } = useCategories();
  const category = categories.find((c) => String(c.id) === String(categoryID));

  if (!category) {
    return null;
  }

  let sex: 'unisex' | 'male' | 'female' = 'unisex';
  if (type === 'male') {
    sex = 'male';
  }
  if (type === 'female') {
    sex = 'female';
  }

  // @ts-ignore
  const categoryPhoto = category.photos[sex];
  const text = `${
    sex !== 'unisex' && language === SUPPORTED_LOCALES.EN
      ? `${tu(sex, 'common')}'s `
      : ''
  }${category.name}${
    sex !== 'unisex' && language !== SUPPORTED_LOCALES.EN
      ? ` ${tu(sex, 'common')}`
      : ''
  }`;

  return (
    <Link
      to={buildItemsListingRoute(type, category.slug)}
      className="min-w-[256px] first:ml-4 last:mr-4 lg:min-w-[360px] xl:min-w-[512px]"
    >
      <CategoryBox name={category.slug} photo={categoryPhoto} text={text} />
    </Link>
  );
};

export const CategoriesCarousel = ({ categoriesToInclude }: Props) => {
  return (
    <div className="flex gap-4 overflow-x-scroll">
      {categoriesToInclude.map((categoryToInclude) => (
        <CategoryCarouselItem
          key={`${categoryToInclude.id}-${categoryToInclude.type}`}
          categoryID={categoryToInclude.id}
          type={categoryToInclude.type}
        />
      ))}
    </div>
  );
};
