import Link from 'next/link';
import React from 'react';

import { Button } from 'revibe-ui';

type Props = {
  heading: string;
  text: string;
  cta?: string;
  to?: string;
  action?: () => void;
};

export const Banner = ({ heading, text, cta, to, action }: Props) => {
  return (
    <div className="w-full bg-gray-50 p-4 text-center md:text-left">
      <div className="flex w-full grid-cols-2 flex-col items-end justify-between gap-4 md:grid">
        <div className="w-full">
          <h1 className="font-formula text-2xl tracking-wide md:text-3xl lg:text-4xl">
            {heading}
          </h1>
          <p className="whitespace-pre-wrap text-xs text-gray-500 md:text-sm">
            {text}
          </p>
        </div>
        <div className="mx-auto h-fit md:ml-0 md:w-fit lg:mr-0 lg:ml-auto">
          {to && (
            <Link href={to}>
              <Button variant={'subtle'}>{cta}</Button>
            </Link>
          )}
          {action && (
            <Button variant={'subtle'} onClick={action}>
              {cta}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
