import React from 'react';

import Image from 'next/image';
import { AspectRatio } from 'revibe-ui';

type Props = {
  name: string;
  photo?: string | null;
  text: React.ReactNode;
  onClick?: () => void;
};

export const CategoryBox = ({ name, photo, text, onClick }: Props) => {
  return (
    <div
      className="font-formula relative h-full w-full overflow-hidden border border-black uppercase "
      onClick={onClick}
    >
      <AspectRatio ratio={3 / 4}>
        {photo ? (
          <Image src={photo} alt={name} fill className="object-cover" />
        ) : (
          <Image
            fill
            src="https://res.cloudinary.com/revibe/image/upload/v1639411886/home/Frame_17_ocht0g.svg"
            alt={name}
            className="object-cover"
          />
        )}
      </AspectRatio>
      <div className="absolute bottom-4 left-4 overflow-hidden border border-black bg-white px-4 py-2 no-underline">
        <p className="-mb-1.5 whitespace-nowrap text-2xl uppercase text-black lg:text-4xl xl:text-6xl">
          {text}
        </p>
      </div>
    </div>
  );
};
