import React from 'react';

import { MarketplaceAPI } from 'revibe-api';

import { BlogArticlePreview } from './BlogArticlePreview';

type Props = {
  articles: MarketplaceAPI.BlogArticle[];
};

export const BlogArticlesCarousel = ({ articles }: Props) => {
  return (
    <div className="flex flex-nowrap gap-8 overflow-x-scroll pt-4">
      {articles.map((article) => (
        <div
          key={article.slug}
          className="min-w-[296px] max-w-[296px] first:ml-4 last:mr-4 lg:min-w-[360px] lg:max-w-[360px]"
        >
          <BlogArticlePreview article={article} />
        </div>
      ))}
    </div>
  );
};
