import React from 'react';

import { cn, Progress } from 'revibe-ui';
import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';

import { useCarouselInterval } from 'modules/common/hooks/useCarouselInterval';

import { Link } from 'ui/components/Link';
import Image from 'next/image';

type Props = {
  photos: {
    src: string;
    heading: string;
    text: string[];
    to: string;
    cta?: string;
  }[];
};

export const PhotoCarousel = ({ photos }: Props) => {
  const { tu } = useTranslation();
  const { index, setIndex, visitedIndexCount, progress } = useCarouselInterval(
    photos.length
  );

  return (
    <div className="lg:aspect-w-4 lg:aspect-h-2 relative mx-auto box-border h-full w-full overflow-hidden border-t border-b border-black bg-gray-50 md:border">
      {photos.map((photo, i) =>
        i < visitedIndexCount || i <= index + 1 ? (
          <div
            className={cn(
              'absolute inset-0 h-full w-full transition-opacity',
              i === index ? 'opacity-100' : 'opacity-0'
            )}
            key={photo.src}
          >
            <Image
              className="max-h-full max-w-full object-cover object-top"
              src={photo.src}
              alt="branding hero"
              fill
            />
          </div>
        ) : (
          <img
            className="absolute inset-0 opacity-0"
            data-src={photo.src}
            alt="branding hero"
            key={photo.src}
          />
        )
      )}
      <div
        className={cn('absolute my-4 box-border grid w-full gap-4 px-4')}
        style={{
          gridTemplateColumns: `repeat(${photos.length}, minmax(0,1fr))`,
        }}
      >
        {photos.map((photo, i) => (
          <Progress
            key={photo.src}
            value={index === i ? progress : 0}
            onClick={() => setIndex(i)}
          />
        ))}
      </div>
      <div className="absolute bottom-0 left-0 flex flex-col justify-end">
        <div className="flex flex-col p-4">
          <h1 className="font-formula mb-2 text-3xl font-bold uppercase text-white md:text-4xl lg:text-6xl">
            {photos[index].heading}
          </h1>
          {photos[index].text.map((line) => (
            <p
              key={line}
              className="max-w-2xl font-light text-white md:text-xl lg:text-2xl"
            >
              {line}
            </p>
          ))}
          <Link className="pt-4" to={photos[index].to} hasDecoration={false}>
            <Button variant="subtle">
              {photos[index].cta || tu('discover-more', I18N_NAMESPACES.COMMON)}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
