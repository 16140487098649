import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';
import { NEWS_ROUTES } from 'routes';

import { Link } from 'ui/components/Link';

import { PressLogoCloud } from './PressLogoCloud';
import { NEWS } from 'modules/news/constants/news';
import { NewsPreview } from 'modules/news/components/NewsPreview';

export const News = () => {
  const { tu } = useTranslation(I18N_NAMESPACES.HOME);

  return (
    <div className="bg-black py-8 text-white">
      <div className="flex h-full flex-col justify-between gap-8 px-4">
        <div>
          <h2 className="font-formula break-words text-4xl uppercase text-white lg:text-6xl">
            {tu('news.heading')}
          </h2>
          <p className="mt-3 max-w-3xl text-lg text-gray-300">
            {tu('news.text')}
          </p>
        </div>
      </div>
      <div className="py-8">
        <PressLogoCloud />
      </div>
      <div className="flex flex-nowrap gap-8 overflow-x-scroll py-4">
        {NEWS.map((news) => (
          <div
            key={news.slug}
            className="min-w-[296px] max-w-[296px] first:ml-4 last:mr-4 lg:min-w-[360px] lg:max-w-[360px]"
          >
            <NewsPreview news={news} />
          </div>
        ))}
      </div>
      <Link className="p-4" hasDecoration={false} to={NEWS_ROUTES.HOME}>
        <Button variant="outline">{tu('news.cta')}</Button>
      </Link>
    </div>
  );
};
