import Marquee from 'react-fast-marquee';
import { Link } from 'ui/components/Link';

const mags = [
  {
    src: 'https://res.cloudinary.com/revibe/image/upload/v1690813395/home/MCLOGO_ulj9qh.png',
    link: 'https://www.marieclaire.it/moda/fashion-news/a43717822/revibe-hm-intervista/',
    alt: 'marie-claire',
  },

  {
    src: 'https://res.cloudinary.com/revibe/image/upload/v1690558410/home/Mediamodifier-Design-Template_2_zncv2t.png',
    link: 'https://www.vogue.it/vogue-talents/article/vogue-yoox-challenge-2021-finalisti',
    alt: 'vogue',
  },
  {
    src: 'https://res.cloudinary.com/revibe/image/upload/v1690558283/home/Mediamodifier-Design-Template_yw3mm4_he38ad.png',
    link: 'https://fr.fashionnetwork.com/news/Revibe-la-plateforme-e-commerce-dediee-a-la-mode-upcyclee,1440953.html',
    alt: 'fashion network',
  },
  {
    src: 'https://res.cloudinary.com/revibe/image/upload/v1690812976/home/Man_In_Town_tjm8vd_1_cifmnu_z0vkoo.png',
    link: 'https://manintown.com/futuro-upcycling-revibe/2022/08/10/',
    alt: 'manintown',
  },
  {
    src: 'https://res.cloudinary.com/revibe/image/upload/v1690558709/home/7zgt5p0fggglkmqwioz_1_mbh0ni.png',
    link: 'https://www.thegoodgoods.fr/events/revibe-festival-levenement-ultime-de-la-mode-upcyclee/',
    alt: 'goodgoods',
  },
  {
    src: 'https://res.cloudinary.com/revibe/image/upload/v1690812767/home/kalt_cfkmcu.png',
    link: 'https://www.kaltblut-magazine.com/hmbeyond-teams-up-with-revibe-for-a-100-upcycled-capsule-collection/',
    alt: 'kaltblut',
  },
  {
    src: 'https://res.cloudinary.com/revibe/image/upload/v1690812866/home/metal_yzybqd.png',
    link: 'https://metalmagazine.eu/en/post/article/revibe-x-h-and-mbeyond',
    alt: 'metal',
  },
];

export const PressLogoCloud = () => {
  return (
    <Marquee
      gradient={false}
      direction="left"
      className="z-0 cursor-pointer"
      pauseOnHover
    >
      {[...mags, ...mags, ...mags, ...mags, ...mags].map((mag, i) => (
        <Link
          key={`${mag.alt}${i}`}
          to={mag.link}
          hasDecoration={false}
          target="_blank"
        >
          <div className="relative mx-4 h-[30px] w-full transition-opacity hover:opacity-50 md:mx-8 md:h-[50px]">
            <img src={mag.src} alt={mag.alt} className="h-[30px] md:h-[50px]" />
          </div>
        </Link>
      ))}
    </Marquee>
  );
};
