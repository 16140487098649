import { useState } from 'react';

import { useInterval } from './useInterval';

export const useCarouselInterval = (total: number) => {
  const [index, setIndex] = useState(0);
  const [visitedIndexCount, setVisitedIndexCount] = useState(0);
  const [progress, setProgress] = useState(0);

  useInterval(() => {
    if (progress >= 100) {
      setProgress(0);
      setVisitedIndexCount(visitedIndexCount + 1);
      if (index === total - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    } else {
      setProgress((t) => t + 1);
    }
  }, total * 20);

  return {
    index,
    setIndex,
    visitedIndexCount,
    progress,
  };
};
