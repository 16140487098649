import React from 'react';

import { useRouter } from 'next/router';

import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import Clamp from 'react-multiline-clamp';
import { AspectRatio, SpacedContainer, cn } from 'revibe-ui';
import { BLOG_ROUTES, SHOP_ROUTES } from 'routes';

import { useAuth } from 'modules/auth/hooks/useAuth';
import { BlogArticlesCarousel } from 'modules/blog/components/BlogArticlesCarousel';
import { Banner } from 'modules/common/components/Banner';
import { BoxContentImage } from 'modules/common/components/BoxContentImage';
import Image from 'next/image';
import { PhotoCarousel } from 'modules/common/components/PhotoCarousel';
import { ShopPage } from 'modules/common/components/ShopPage';
import { TextMarquee } from 'modules/common/components/TextMarquee';
import { getDynamicRouteUrl } from 'modules/common/utils';
import { ItemsCarousel } from 'modules/item/components/ItemsCarousel';
import { ITEM_FILTERS } from 'modules/item/utils/itemFilter';
import { CategoriesCarousel } from 'modules/catalog/components/CategoriesCarousel';
import { CategoriesNavBar } from 'modules/catalog/components/CategoriesNavBar';

import { Link } from 'ui/components/Link';
import { LogoIcon } from 'ui/icons/LogoIcon';

import { News } from '../components/News';
import { MarketplaceAPI } from 'revibe-api';

type Props = {
  articles: MarketplaceAPI.BlogArticle[] | null;
  igPosts: {
    media_url: string;
    permalink: string;
    id: string;
    caption: string;
    media_type: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM';
  }[];
};

export const HomePage = ({ articles, igPosts }: Props) => {
  const { tu, t } = useTranslation(I18N_NAMESPACES.HOME);
  const { isLoggedIn, authModal } = useAuth();
  const router = useRouter();

  const sexCat = [
    {
      image:
        'https://res.cloudinary.com/revibe/image/upload/f_auto/v1667913458/home/Women_ygwaiu.jpg',
      sex: 'female',
    },
    {
      image:
        'https://res.cloudinary.com/revibe/image/upload/f_auto/v1667913326/home/Men_ircjbb.jpg',
      sex: 'male',
    },
  ];

  const missingIgPostsGridSm = 2 - (igPosts.length % 2);
  const missingIgPostsGridXl = 3 - (igPosts.length % 3);

  return (
    <ShopPage
      title={tu('home', I18N_NAMESPACES.PAGE_TITLES)}
      isLogoAlwaysVisible={false}
      shouldPostfixTitle={false}
      isShopPage
    >
      <div className="mt-6 flex h-[calc(100vh_-_7rem)] flex-col gap-4 lg:h-full">
        <div className="mx-4">
          <div className="mx-auto bg-white lg:pb-6">
            <LogoIcon
              className="child:fill-black h-10 w-full md:h-12 "
              onClick={() => router.push('/')}
            />
          </div>
          <div className="relative hidden lg:block">
            <CategoriesNavBar />
          </div>
        </div>
        <div className="h-full flex-1 overflow-hidden lg:mx-4">
          <PhotoCarousel
            photos={[1, 2, 3, 4].map((index) => ({
              src: t(`hero.${index}.image`),
              heading: tu(`hero.${index}.heading`),
              text: [tu(`hero.${index}.text`)],
              to: t(`hero.${index}.url`),
              cta: tu(`hero.${index}.cta`),
            }))}
          />
        </div>
      </div>
      <div className="border-b border-black md:hidden">
        <TextMarquee
          direction="left"
          text={tu('free-delivery-banner', 'shop')}
        />
      </div>
      <div className="flex flex-col gap-4 md:mt-4">
        <div className="grid grid-cols-1 gap-[1px] border-b border-black bg-black md:mx-4 md:grid-cols-2 md:gap-4 md:border-b-0 md:bg-white">
          {sexCat.map(({ sex, image }) => (
            <Link
              key={sex}
              to={getDynamicRouteUrl(SHOP_ROUTES.CATEGORIES_SEX, {
                sex,
              })}
              className="relative border-black md:border"
            >
              <AspectRatio ratio={3 / 4}>
                <Image
                  src={image}
                  alt={tu(sex, 'common')}
                  fill
                  className="object-cover"
                />
              </AspectRatio>
              <div className="absolute bottom-4 left-4 overflow-hidden border border-black bg-white px-4 py-2 no-underline">
                <p className="font-formula -mb-1.5 whitespace-nowrap text-2xl uppercase text-black lg:-mb-2.5 lg:text-4xl xl:text-6xl">
                  {tu(sex, 'common')}
                </p>
              </div>
            </Link>
          ))}
        </div>

        <div className="mx-4">
          <BoxContentImage
            heading={tu('sections.1.heading')}
            description={tu('sections.1.description')}
            to={tu('sections.1.url')}
            imageSrc={t('sections.1.image')}
          />
        </div>

        <ItemsCarousel
          label=""
          list="Home page top picks"
          hideSeeAll
          filters={{ [ITEM_FILTERS.SELECTION]: '7' }}
        />

        {!isLoggedIn ? (
          <div className="border-y border-black">
            <Banner
              heading={tu('join.heading')}
              text={tu('join.text')}
              cta={tu('join.cta')}
              action={authModal.open}
            />
          </div>
        ) : (
          <div className="hidden border-none" />
        )}

        <div className="mx-4">
          <BoxContentImage
            imageFirst
            heading={tu('sections.2.heading')}
            description={tu('sections.2.description')}
            to={tu('sections.2.url')}
            imageSrc={t('sections.2.image')}
          />
        </div>

        <CategoriesCarousel
          categoriesToInclude={[
            { id: '23', type: 'female' },
            { id: '23', type: 'male' },
            { id: '25', type: 'female' },
            { id: '25', type: 'male' },
            { id: '24', type: 'female' },
            { id: '24', type: 'male' },
            { id: '22', type: 'female' },
            { id: '22', type: 'male' },
            { id: '28', type: 'female' },
            { id: '21', type: 'male' },
            { id: '41', type: 'female' },
            { id: '51', type: 'unisex' },
            { id: '61', type: 'female' },
            { id: '61', type: 'male' },
          ]}
        />

        {/* <div className="mx-4">
          <BoxContentImage
            heading={tu('about-us.heading')}
            description={tu('about-us.text')}
            to={COMPANY_ROUTES.ABOUT_US}
            imageSrc="https://res.cloudinary.com/revibe/image/upload/f_webp/dpr_auto,f_auto,q_auto/v1643729241/home/About_Us_skeoer_2_neqbk9.webp"
            ctaText={tu('about-us.cta')}
          />
        </div> */}

        <News />

        <div className="bg-white pt-2">
          <SpacedContainer fullWidth className="p-4">
            <Link to={BLOG_ROUTES.HOME} hasDecoration={false}>
              <h1 className="font-syne hover:text-green-fluo break-words text-4xl font-semibold uppercase text-black lg:text-6xl">
                Stain magazine
              </h1>
            </Link>
            <p className="text-black">
              We investigate the world of upcycling, sustainability, design,
              technology and much more. <br /> Curated by Revibe.
            </p>
          </SpacedContainer>
          {articles && <BlogArticlesCarousel articles={articles} />}
        </div>

        <div className="m-4 border border-black bg-gray-50">
          <SpacedContainer>
            <div className="px-4 pt-8 pb-4">
              <h1 className="font-formula break-words text-4xl font-semibold uppercase tracking-wide text-black lg:text-6xl">
                {tu('ig.heading')}
              </h1>
              <p className="break-words text-xs text-black lg:text-sm">
                {tu('ig.subheading1')}
              </p>
              <p className="break-words text-xs text-black lg:text-sm">
                {tu('ig.subheading2')}
              </p>
            </div>
            <div className="box-border grid grid-cols-2 gap-[1px] border-t border-black bg-black xl:grid-cols-3">
              {igPosts.map((igPost) => (
                <div key={igPost.id}>
                  <div className="relative cursor-pointer overflow-hidden">
                    <Link hasDecoration={false} to={igPost.permalink}>
                      <div className="cursor-pointer bg-gray-100 hover:text-black">
                        <AspectRatio ratio={3 / 3}>
                          {igPost.media_type === 'VIDEO' ? (
                            <video
                              src={igPost.media_url}
                              className="h-full w-full object-cover object-center"
                              muted
                              autoPlay
                              loop
                              playsInline
                            />
                          ) : (
                            <Image
                              src={igPost.media_url}
                              alt={igPost.caption}
                              className="bg-[#f3f3f3] object-cover"
                              fill
                            />
                          )}
                        </AspectRatio>
                      </div>
                    </Link>
                    <Link
                      hasDecoration={false}
                      to={igPost.permalink}
                      className="absolute inset-0 flex flex-col justify-end bg-black/40 p-4 text-white opacity-0 duration-300 hover:opacity-100"
                    >
                      <Clamp
                        lines={5}
                        withToggle={false}
                        maxLines={100}
                        showMoreElement={null}
                        showLessElement={null}
                      >
                        <p className="max-w-lg whitespace-pre-wrap text-xs text-white">
                          {igPost.caption}
                        </p>
                      </Clamp>
                    </Link>
                  </div>
                </div>
              ))}
              <div
                className={cn(
                  'hidden h-full w-full bg-white',
                  missingIgPostsGridSm === 1 && 'block xl:hidden'
                )}
              />
              <div
                className={cn(
                  'hidden h-full w-full bg-white',
                  missingIgPostsGridXl === 1 && 'xl:block',
                  missingIgPostsGridXl === 2 && 'col-span-2 xl:block'
                )}
              />
            </div>
          </SpacedContainer>
        </div>
      </div>
    </ShopPage>
  );
};
